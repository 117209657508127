$light-beige: #f1ede2;
$body-bg: #fff;

html, body {
  background: $light-beige;
}

.home {
  background: $body-bg !important;
}
